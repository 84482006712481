import { Box, ButtonProps, Grid, TextField, Typography } from "@mui/material";
import { Formik, FormikConfig, FormikErrors, FormikProps } from "formik";
import React from "react";
import { IProvider } from "models/provider";

import * as yup from "yup";
import { AddProviderCode } from "api/providers-code/add-provider-code";
import { UpdateProviderCode } from "api/providers-code/update-provider-code";
import { IProviderCode } from "models/provider-code";
import { GetProvidersCodeById } from "api/providers-code/get-providers-code-by-id";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CustomTextField, { CustomTextFieldProps } from "components/custom-compnents/custom-text-field/custom-text-field";
import { useAppSelector } from "store/hooks";
import ProvidersInput from "components/input-components/providers-input/providers-input";

export interface AddUpdateAgentCodeProps {
  open?: boolean;
  onCancel: () => void;
}

export interface AddInterface {
  Variant: "add";
  onAdded: () => void;
}

export interface UpdateInterface {
  Variant: "update";
  onUpdate: () => void;
  AgentCodeObject?: IProviderCode;
}

export interface Fields {
  code: string;
  provider?: Pick<IProvider, "id" | "name">;
  comment: string;
  provider_id?: string;
}

export type CombinedType = AddUpdateAgentCodeProps & (UpdateInterface | AddInterface);
const SubmitButtonRef = React.createRef<HTMLButtonElement>();
const AddUpdateAgentCode: React.FC<CombinedType> = ({ open, onCancel, ...props }) => {
  const user = useAppSelector((root) => root.appReducer.user);
  const FieldsData = [
    {
      field: "code",
      placeholder: "Input Code ...",
      label: "Code",
      type: "text",
      value: "",
    },
    {
      field: "provider",
      placeholder: "Select Organization",
      label: "Organizations",
      type: "selectMenu",
      value: user?.menuroles !== "admin" ? user?.provider_id : "",
      disabled: user?.menuroles !== "admin",
    },
    {
      field: "comment",
      placeholder: "Input Comment ...",
      label: "Comment",
      type: "text",
      value: "",
      multiline: true,
      rows: 4,
    },
  ];

  const [LoadingUpdateData, SetLoadingUpdateData] = React.useState(false);

  const validationSchema = yup.object().shape({
    code: yup.string().required(),
    ...(user?.menuroles === "admin"
      ? {
        provider: yup.object().shape({
          id: yup.string().required().not(["none"], "Organization is required field"),
        }),
      }
      : {}),
  });

  const [InitalFormikValues, setInitalFormikValues] = React.useState({
    code: "",
    provider: { name: "", id: "" },
    provider_id: "",
    comment: "",
  });

  React.useEffect(() => {
    if (props.Variant === "update") {
      SetLoadingUpdateData(true);

      props.AgentCodeObject &&
      GetProvidersCodeById({ id: props?.AgentCodeObject?.id || undefined }, (error, resp) => {
          SetLoadingUpdateData(false);
          if (error) {
            console.log(error);
          } else if (resp) {
            setInitalFormikValues({
              ...(resp.data as any)
            });
          }
        });
    }
  }, [props.Variant === "update" && JSON.stringify(props.AgentCodeObject)]);

  const FormikProps: FormikConfig<Fields> = {
    enableReinitialize: true,
    initialValues: {
      code: InitalFormikValues.code,
      provider: InitalFormikValues?.provider,
      provider_id: InitalFormikValues?.provider?.id,
      comment: InitalFormikValues.comment,
    },
    onSubmit: (values, actions) => {
      if (props.Variant === "add")
        AddProviderCode({
          code: values.code,
          comment: values.comment,
          provider_id: values?.provider?.id
        }, (error, resp) => {
          actions.setSubmitting(false);
          if (error) {
            if (error.response?.status == 422)  {
              const err = error.response.data.message as FormikErrors<any>;
              actions.setErrors(err);
            } 
            console.log(error.message);
          } else {
            props.onAdded();
            actions.resetForm();
          }
        });
      else if (props.Variant === "update" && props.AgentCodeObject) {
        UpdateProviderCode(
          {
            id: props.AgentCodeObject.id,
            code: values.code,
            comment: values.comment,
            provider_id: values?.provider?.id
          },
          (error, resp) => {
            actions.setSubmitting(false);
            if (error) {
              console.log(error.message);
              if (error.response?.status == 422) actions.setErrors(error.response.data.errors);
            } else if (resp) {
              props.onUpdate();
              actions.resetForm();
            }
          }
        );
      }
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
  };

  const TextFieldsProps: (FormikProps: FormikProps<Fields>, arg1: any, number: number) => CustomTextFieldProps = (FormikProps, item, index) => ({
    rows: item?.rows,
    multiline: item?.multiline,
    error: Boolean((FormikProps as any).errors[item?.field]),
    helperText: (FormikProps as any).errors[item?.field],
    value: (FormikProps as any).values[item?.field],
    onChange: FormikProps.handleChange,
    name: item?.field,
    autoFocus: index === 0,
    size: "small",
    fullWidth: true,
    key: index,
    placeholder: item?.placeholder,
    // label: item.label,
  });

  const SubmitButtonProps: ButtonProps = {
    type: "submit",
    ref: SubmitButtonRef,
    style: { display: "none" },
  };

  return (
    <Formik {...FormikProps}>
      {(FormikProps) => (
        <CustomDialogV2
          header={props.Variant === "add" ? "Add Registration Code" : "Update Registration Code"}
          open={open ?? false}
          footer={
            <Box display="flex" justifyContent="flex-end">
              <CancelSuccessButtonsGroup
                CancelButtonProps={{ label: "Cancel", onClick: onCancel }}
                SuccessButtonProps={{
                  label: props.Variant === "add" ? "Add Code" : "Save",
                  disabled: FormikProps.isSubmitting,
                  onClick: () => SubmitButtonRef.current?.click(),
                }}
                RootGridProps={{ width: 300 }}
              />
            </Box>
          }
        >
          {/* <CustomDialog {...DialogProps(FormikProps)}> */}
          <Box maxWidth={350} mt={1}>
            <form onSubmit={FormikProps.handleSubmit}>
              <Grid container spacing={2}>
                {LoadingUpdateData ? (
                  <Grid item>
                    <Typography>Loading ...</Typography>
                  </Grid>
                ) : (
                  FieldsData.map((item, index) => (
                    <Grid key={index} xs={12} item>
                      {item.type === "selectMenu" ? (
                        <ProvidersInput value={FormikProps.values?.provider?.id} error={Boolean(FormikProps.errors["provider"])} helperText={FormikProps?.errors?.provider_id} onChange={(value) => !Array.isArray(value) && FormikProps.setFieldValue("provider", value)} />
                      ) : item.multiline ? (
                        <TextField variant="outlined" {...TextFieldsProps(FormikProps, item, index)} />
                      ) : (
                        <CustomTextField {...TextFieldsProps(FormikProps, item, index)} />
                      )}
                    </Grid>
                  ))
                )}
              </Grid>
              <button {...SubmitButtonProps} />
            </form>
          </Box>
          {/* </CustomDialog> */}
        </CustomDialogV2>
      )}
    </Formik>
  );
};

export default AddUpdateAgentCode;
