import { AxiosInstance } from "configs/axios";

const DeleteFarmersApi = (Id: string, callback?: () => void) => {
  AxiosInstance.delete("/farmers/" + Id)
    .then((resp) => {
      callback && callback();
    })
    .catch((err) => console.log(err));
};

export { DeleteFarmersApi };
