import React from "react";
import CustomProvidersTable from "components/custom-compnents/custom-providers-table/custom-providers-table";
import { excludeColumnsType, includeActionsType, IParams, useLogic } from "./providers-table.logic";
import AddUpdateProviderComponent from "components/standalone-components/Add-Update-Provider/Add-Update-Provider";
import RemoveProviderModal from "components/standalone-components/remove-provider/remove-provider-modal";
import AffiliateOrganizationDialog from "components/standalone-components/affiliate-organization-dialog/affiliate-organization-dialog";
import DisassociateOrganizationDialog from "components/standalone-components/disassociate-organization-dialog/disassociate-organization-dialog";
import NoteDialog from "components/custom-compnents/note-dialog/note-dialog";
import SetupAgriWalletModal from "components/standalone-components/setup-agriwallet/setup-agriwallet-modal";

export interface ProvidersTableProps extends IParams {
  farmersIds?: string[];
  providersIds?: string[];
  columnsToExclude?: excludeColumnsType;
  actionsToInclude?: includeActionsType;
}

const ProvidersTable: React.FC<ProvidersTableProps> = (props) => {
const { tableProps, addProviderProps, updateProviderProps, removeProviderProps, afiliateDialogProps, afiliateOwnOrgDialogProps, disassociateOrganizationDialogProps, isOpenAddPhoneNoteModal, handleHidePhoneNote, setupAgriWalletProps } = useLogic(props);

  return (
    <>
      <NoteDialog open={isOpenAddPhoneNoteModal} onHideClick={handleHidePhoneNote} content="Please update phone number on your organization first" header="Note" />
      <AffiliateOrganizationDialog {...afiliateOwnOrgDialogProps} />
      <AffiliateOrganizationDialog {...afiliateDialogProps} />
      <CustomProvidersTable {...tableProps} />
      <AddUpdateProviderComponent {...addProviderProps} />
      <AddUpdateProviderComponent {...updateProviderProps} />
      <RemoveProviderModal {...removeProviderProps} />
      <DisassociateOrganizationDialog {...disassociateOrganizationDialogProps} />
      <SetupAgriWalletModal {...setupAgriWalletProps}/>
    </>
  );
};

export default ProvidersTable;
