import { Autocomplete, AutocompleteChangeReason, TextField } from "@mui/material";
import { IProvider } from "models/provider";
import { IParams, useLogic, viewType } from "./providers-input.logic";

export type Value = IProvider | IProvider[] | null | (IProvider | IProvider[])[];
export interface ProvidersInputProps extends IParams {
  onChange?: (value: Value, reason: AutocompleteChangeReason) => any;
  placeholder?: string;
  fullwidth?: boolean;
  limitTags?: number;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

const ProvidersInput: React.FunctionComponent<ProvidersInputProps & viewType> = (props) => {
  const { value, onChange, placeholder, fullwidth, limitTags, error, helperText, disabled, ...rest } = props;
  const { loading, providers, setsearchTerm, providerValue } = useLogic({
    value,
    ...rest,
  });

  return (
    <Autocomplete
      limitTags={limitTags}
      fullWidth={fullwidth}
      loading={loading}
      loadingText="Loading ..."
      filterSelectedOptions
      options={loading ? [] : providers}
      getOptionLabel={(provider) => (!Array.isArray(provider) ? provider.name : "")}
      value={providerValue ? providerValue : Array.isArray(value) ? [] : null}
      multiple={Array.isArray(value)}
      renderInput={(props) => <TextField {...props} placeholder={placeholder} error={error} helperText={helperText} disabled={disabled} />}
      onChange={(e, value, reason) => onChange?.(value, reason)}
      onInputChange={(e, value) => setsearchTerm(value)}
    />
  );
};

export default ProvidersInput;

ProvidersInput.defaultProps = {
  placeholder: "Search Organization",
  viewType: "all",
};
