import { Box, Chip, Switch, TableBody, TableCell, TableRow } from "@mui/material";
import tableIcons from "configs/Icons";
import { MTableBody, MaterialTableProps } from "@material-table/core";
import CustomCheckBox from "../custom-checkbox/custom-checkbox";
import EmptyTableLabelComponent from "../empty-table-component/empty-table-label-component";
import { CustomFarmersTableActionBarProps } from "../custom-farmers-table-action-bar/custom-farmers-table-action-bar";
import { IProvider } from "models/provider";
import ActionButtonMenu from "../action-button-menu/action-button-menu";

export interface selectionProps {
  onSelectAllChange?: (data: Fields[], value: boolean) => any;
  selectAllchecked?: boolean;
  onRowSelectChange?: (data: Fields, value: boolean) => any;
}
export interface Fields extends IProvider {
  selected?: boolean;
  disabledIsBillings?: boolean;
}

export interface Params {
  customSelection?: selectionProps;
  data: Fields[];
  columnState?: CustomFarmersTableActionBarProps["columnsState"];
  onEditButtonClick?: (data: Fields) => any;
  onDeleteButtonClick?: (data: Fields) => any;
  onAffiliateClick?: (data: Fields) => any;
  onDissociateClick?: (data: Fields) => any;
  onBillingSwitchClick?: (data: Fields, status: boolean) => any;
  onSetupeWalletClick?: (data: Fields) => any;
  isShowRemoveAction?: boolean;
  isShowEditAction?: boolean;
  isShowAffiliateAction?: boolean;
  isShowDissociateAction?: boolean;
  isShowsetupewalletAction?: boolean;
  viewType?: "super_orgs" | "sub_orgs" | "all";
}

export const schema: (params: Params) => MaterialTableProps<Fields> = (params) => {
  const columns: MaterialTableProps<Fields>["columns"] = [
    {
      title: "ID",
      field: "id",
      hidden: !params.columnState?.find((item) => item.field === "id")?.checked,
      render: (data) => data.id,
    },
    {
      title: "NAME",
      field: "name",
      hidden: !params.columnState?.find((item) => item.field === "name")
        ?.checked,
      render: (data) => data.name,
    },
    {
      title: "TYPE",
      field: "type",
      hidden: !params.columnState?.find((item) => item.field === "type")
        ?.checked,
      render: (data) => data.type,
    },

    {
      title:
        params.viewType === "sub_orgs"
          ? "Take Over Billing"
          : "Is Billing Organization",
      field: "billing",
      sorting: false,
      hidden:
        !params.columnState?.find((item) => item.field === "billing")
          ?.checked ||
        (params.viewType !== "sub_orgs" && params.viewType !== "super_orgs"),
      render: (data) => {
        return (
          <Switch
            readOnly={params.viewType === "super_orgs"}
            checked={data.isBillingOrganization}
            onChange={
              params.viewType === "sub_orgs"
                ? (e, status) => params.onBillingSwitchClick?.(data, status)
                : undefined
            }
          />
        );
      },
    },
    // {
    //   title: "Super Organization",
    //   sorting: false,
    //   field: "super_org",
    //   hidden: !params.columnState?.find((item) => item.field === "super_org")
    //     ?.checked,
    //   render: (data) => "-",
    // },

    {
      title: "E-MAIL",
      field: "email",
      hidden: !params.columnState?.find((item) => item.field === "email")
        ?.checked,
      render: (data) => data.email,
    },
    {
      title: "Current Location",
      field: "location",
      hidden: !params.columnState?.find((item) => item.field === "location")
        ?.checked,
      render: (data) =>
        data.store_gps_lat
          ? data.store_gps_lat + " / " + data.store_gps_lon
          : "-",
    },
    {
      title: "SMS SIGNATURE NAME",
      field: "smsSigName",
      hidden: !params.columnState?.find((item) => item.field === "smsSigName")
        ?.checked,
      render: (data) => data.smsSigName,
    },
    {
      title: "IS ACTIVE?",
      field: "isActive",
      hidden: !params.columnState?.find((item) => item.field === "isActive")
        ?.checked,
      render: (data) =>
        data.isActive ? (
          <Chip label="Yes" variant="outlined" />
        ) : (
          <Chip label="No" variant="outlined" color="error" />
        ),
    },
    {
      title: "AGRIWALLET",
      field: "ewallet_user",
      hidden: !params.columnState?.find((item) => item.field === "ewallet_user")
        ?.checked,
      render: (data) => {
        return data.ewallet_user ? (
          <Chip label="Set up" variant="outlined" />
        ) : (
          <Chip label="Not set up" variant="outlined" color="error" />
        );
      },
    },
  ];

  if (params.customSelection) {
    columns.unshift({
      title: <CustomCheckBox onChange={(e, value) => params.customSelection?.onSelectAllChange?.(params.data, value)} checked={params.data.every((item) => item.selected)} indeterminate={params.data.some((item) => item.selected) && !params.data.every((item) => item.selected)} />,
      field: "select",
      sorting: false,
      width: "5%",
      render: (data) => <CustomCheckBox checked={data.selected} onChange={(e, value) => params.customSelection?.onRowSelectChange?.(data, value)} />,
    });
  }

  const isActionsEmpty = !params.isShowEditAction && !params.isShowRemoveAction && !params.isShowAffiliateAction && !params.isShowDissociateAction;

  return {
    actions: isActionsEmpty
      ? undefined
      : [
        (fields) => ({
          icon: () => (
            <ActionButtonMenu
              menuProps={{
                menuData: [
                  {
                    hidden: !params.isShowEditAction,
                    label: "Edit Info",
                    onClick: () => params.onEditButtonClick?.(fields),
                  },
                  {
                    hidden: !params.isShowRemoveAction,
                    label: "Delete Organization",
                    onClick: () => params.onDeleteButtonClick?.(fields),
                  },
                  // {
                  //   hidden: !params.isShowAffiliateAction,
                  //   label: "Affiliate To",
                  //   onClick: () => params.onAffiliateClick?.(fields),
                  // },
                  // {
                  //   hidden: !params.isShowsetupewalletAction || !!fields.ewallet_user?.id,
                  //   label: "Set up agriWALLET",
                  //   onClick: () => params.onSetupeWalletClick?.(fields),
                  // },
                  // {
                  //   hidden: !params.isShowDissociateAction,
                  //   label: "Disassociate Organization",
                  //   onClick: () => params.onDissociateClick?.(fields),
                  // },
                ],
              }}
            />
          ),
          onClick: () => null,
        }),
      ],

    columns,
    data: params.data,
    icons: tableIcons,

    options: {
      paging: false,
      toolbar: false,
      actionsColumnIndex: -1,
      draggable: false,
    },

    components: {
      Action: (props) => {
        return <Box children={props.action.action(props.data).icon()} />;
      },
      Body: (props) =>
        props.renderData.length === 0 ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={9}>
                <Box my={10}>
                  <EmptyTableLabelComponent paragraph="" />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <MTableBody {...props} />
        ),
    },
  };
};
