import { CustomProvidersTableProps } from "components/custom-compnents/custom-providers-table/custom-providers-table";
import { IProvider } from "models/provider";
import React from "react";
import { GetProvider } from "api/provider/get-provider";
import { debounce } from "lodash";
import { propsType } from "components/standalone-components/Add-Update-Provider/Add-Update-Provider";
import { RemoveProviderModalProps } from "components/standalone-components/remove-provider/remove-provider-modal";
import { AffiliateOrganizationDialogProps } from "components/standalone-components/affiliate-organization-dialog/affiliate-organization-dialog";
import { useAppSelector } from "store/hooks";
import { Params } from "components/custom-compnents/custom-providers-table/custom-providers-table.schema";
import { DisassociateOrganizationDialogProps } from "components/standalone-components/disassociate-organization-dialog/disassociate-organization-dialog";
import { updateSuperOrganizationRelation } from "api/super-organization-relation/update-super-organization-relation";
import { SetupAgriWalletModalProps } from "components/standalone-components/setup-agriwallet/setup-agriwallet-modal";
import { PageInfo } from "models/page-info";

export type excludeColumnsType = ("id" | "name" | "type" | "email" | "smsSigName" | "isActive")[];

export type includeActionsType = ("add" | "remove" | "edit" | "affiliate" | "disassociate" | "inline_affiliate")[];

interface ResponseType {
  pageInfo: PageInfo;
  totalCount: number;
  providers: IProvider[]; // Assuming `providers` is an array of farmer objects
}

interface updateBillingInTableParams {
  id: string;
  status: boolean;
}

export interface IParams {
  actionsToInclude?: includeActionsType;
  columnsToExclude?: excludeColumnsType;
  farmersIds?: string[];
  providersIds?: string[];
  viewType?: Params["viewType"];
}

export const useLogic = (params: IParams) => {
  const [superOrgIdToDisassociate, setsuperorgIdToDisassociate] = React.useState("");
  const [isOpenAddPhoneNoteModal, setisOpenAddPhoneNoteModal] = React.useState(false);
  const [subOrgIdToDisassociate, setsubOrgIdToDisassociate] = React.useState("");
  const [isOpenAffliateOwnOrgDialog, setisOpenAffliateOwnOrgDialog] = React.useState(false);
  const [subOrgToAffiliate, setsubOrgToAffiliate] = React.useState("");
  const [subOrgNameToAffiliate, setsubOrgNameToAffiliate] = React.useState("");
  const [openAddProviderModal, setOpenAddProvideModal] = React.useState(false);
  const [providerInfoToBeRemoved, setProviderInfoToBeRemoved] = React.useState<undefined | IProvider>(undefined);
  const [providerInfoToSetupAgriWallet, setProviderInfoToSetupAgriWallet] = React.useState<undefined | IProvider>(undefined);
  const [openRemoveProviderModal, setOpenRemoveProviderModal] = React.useState(false);
  const [openUpdateProviderModal, setOpenUpdateProviderModal] = React.useState(false);

  const [openSetupAgriWalletModal, setOpenSetupAgriWalletModal] = React.useState(false);
  const [providerInfoToBeUpdate, setProviderInfoToBeUpdate] = React.useState<undefined | Pick<IProvider, "name" | "id">>(undefined);
  const [data, setData] = React.useState<IProvider[]>([]);
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [enableBilling, setenableBilling] = React.useState(true);
  const [subOrgToUpdateBilling, setsubOrgToUpdateBilling] = React.useState<{ status: boolean; org_id: string } | undefined>(undefined);

  const [pageInfo, setPageInfo] = React.useState<PageInfo>({
    has_previous_page: false, 
    has_next_page: false, 
    start_cursor: "", 
    end_cursor: "", 
  });

  const [isPageChange, setIsPageChange] = React.useState(true);

  const [isNextChanged, setIsNextChanged] = React.useState(false);

  const [isPrevChanged, setIsPrevChanged] = React.useState(false);


  const isLastPage = !pageInfo?.has_next_page;

  const isFirstPage = !pageInfo?.has_previous_page;


  const [columnsState, setColumnsState] = React.useState([
    { field: "id", checked: true },
    { field: "name", checked: true },
    { field: "type", checked: true },
    { field: "email", checked: true },
    { field: "isActive", checked: true },
  ]);
  const user = useAppSelector((root) => root.appReducer.user);

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  const handleAddProviderClicked = () => setOpenAddProvideModal(true);

  const handleUpdateProviderClicked = (Provider: IProvider) => {
    setProviderInfoToBeUpdate(Provider);
    setOpenUpdateProviderModal(true);
  };

  const handleCancelAddProvider = () => setOpenAddProvideModal(false);

  const handleAdded = () => {
    refreshData();
    setOpenAddProvideModal(false);
  };

  const handleUpdated = () => {
    refreshData();
    setOpenUpdateProviderModal(false);
    setProviderInfoToBeUpdate(undefined);
  };

  const handleAddEWallet = (fields: IProvider) => {

    if (!fields.phone) setisOpenAddPhoneNoteModal(true);

    if (fields.phone) {
      setOpenSetupAgriWalletModal(true);
      setProviderInfoToSetupAgriWallet(fields);
    }
  };

  const handleCancelUpdate = () => {
    setOpenUpdateProviderModal(false);
    setProviderInfoToBeUpdate(undefined);
  };

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(1);
    setIsPageChange(true);
  };

  const handlePreviousPageClick = () => {
    if (!isFirstPage) {
      setIsLoading(true);
      setIsPageChange(true);
      setIsNextChanged(false);
      setIsPrevChanged(true);
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearchTextChange = debounce((value: string) => {
    setSearchText(value);
    setCurrentPage(0);
  }, 900);

  const handleNextPageClick = () => {
    if (!isLastPage) {
      setIsLoading(true);
      setIsPageChange(true);
      setIsPrevChanged(false);
      setIsNextChanged(true);
      setCurrentPage(currentPage + 1);
    }
  };

  const handleRemoveProviderClicked = (Provider: IProvider) => {
    setOpenRemoveProviderModal(true);
    setProviderInfoToBeRemoved(Provider);
  };
  const handleCancelRemoveProvider = () => {
    setOpenRemoveProviderModal(false);
    setProviderInfoToBeRemoved(undefined);
  };
  const handleDeleted = () => {
    setOpenRemoveProviderModal(false);
    setProviderInfoToBeRemoved(undefined);
    refreshData();
  };


  const handleCancelSetupAgriWallet = () => {
    setOpenSetupAgriWalletModal(false);
    setProviderInfoToSetupAgriWallet(undefined)
  };
  const handleConfirmAgriWallet = () => {
    setOpenSetupAgriWalletModal(false);
    setProviderInfoToSetupAgriWallet(undefined);
    refreshData();
  };


  const handleHidePhoneNote = () => setisOpenAddPhoneNoteModal(false);

  // update billing in table
  const updateBilingInTable = (params: updateBillingInTableParams) => {
    const { id, status } = params;
    const index = data.findIndex((item) => item.id === id);
    let clonedData = [...data];
    clonedData[index] = {
      ...clonedData[index],
      isBillingOrganization: status,
    };

    setData(clonedData);
  };

  // update billings
  React.useEffect(() => {
    if (subOrgToUpdateBilling) {
      setenableBilling(false);
      updateBilingInTable({
        id: subOrgToUpdateBilling?.org_id,
        status: subOrgToUpdateBilling?.status,
      });
      updateSuperOrganizationRelation({
        super_org: user?.provider_id!,
        sub_org: subOrgToUpdateBilling?.org_id,
        bill_to_super: subOrgToUpdateBilling?.status,
      }).then(() => {
        setenableBilling(true);
        setsubOrgToUpdateBilling(undefined);
      });
    }
  }, [subOrgToUpdateBilling?.org_id, subOrgToUpdateBilling?.status]);

  // fetching providers
  React.useEffect(() => {
    if (isPageChange) {
      setIsLoading(true);
      GetProvider(
        {
          farmer_id_list: params.farmersIds,
          pageSize: perPage,
          page: currentPage,
          search: searchText,
          before: pageInfo.has_previous_page ? pageInfo?.start_cursor : undefined,
          after: pageInfo.has_next_page ? pageInfo?.end_cursor : undefined,
          isPrevChanged,
          isNextChanged,
          id: params.viewType !== "all" ? user?.provider_id : undefined,
          sub_orgs: params.viewType === "sub_orgs",
          super_orgs: params.viewType === "super_orgs",
          with_ewallet_user: true,
        },
        (error, response) => {
          if (response) {
            const resp = response as ResponseType;
            setData(resp.providers);
            setTotalCount(resp.totalCount);
            setPageInfo(resp.pageInfo);
            setIsLoading(false);
            setIsPageChange(false);
          } else if (error) {
            console.log(error.message);
          }
        }
      );
    }
  }, [dataUpdateIndex, currentPage, perPage, searchText, params.farmersIds, params.viewType, isPageChange]);

  const addProviderProps: propsType = {
    variant: "add",
    onAdded: handleAdded,
    onCancel: handleCancelAddProvider,
    open: openAddProviderModal,
    is_sub_org: params.viewType === "sub_orgs",
  };

  const updateProviderProps: propsType = {
    variant: "update",
    onUpdated: handleUpdated,
    onCancel: handleCancelUpdate,
    open: openUpdateProviderModal,
    Provider: providerInfoToBeUpdate,
  };

  const removeProviderProps: RemoveProviderModalProps = {
    onCancel: handleCancelRemoveProvider,
    onDelete: handleDeleted,
    open: openRemoveProviderModal,
    Provider: providerInfoToBeRemoved,
  };

  const afiliateDialogProps: AffiliateOrganizationDialogProps = {
    open: Boolean(subOrgToAffiliate),
    sub_org: subOrgToAffiliate,
    onAffiliated: () => {
      setsubOrgToAffiliate("");
      refreshData();
    },
    onCancel: () => setsubOrgToAffiliate(""),
    sub_org_name: subOrgNameToAffiliate,
  };

  const afiliateOwnOrgDialogProps: AffiliateOrganizationDialogProps = {
    open: Boolean(isOpenAffliateOwnOrgDialog),
    sub_org: user?.provider_id,
    onAffiliated: () => {
      setisOpenAffliateOwnOrgDialog(false);
      refreshData();
    },
    onCancel: () => setisOpenAffliateOwnOrgDialog(false),
    sub_org_name: user?.providerName,
  };

  const setupAgriWalletProps: SetupAgriWalletModalProps = {
    onCancel: handleCancelSetupAgriWallet,
    onCreate: handleConfirmAgriWallet,
    open: openSetupAgriWalletModal,
    Provider: providerInfoToSetupAgriWallet,
  };

  const preparedData = data.map((item) => ({
    ...item,
    selected: false,
    disabledIsBillings: !enableBilling,
  }));

  const preparedColumnsState = columnsState.filter((item) => !params.columnsToExclude?.find((columnsToExcludeItem) => columnsToExcludeItem === item.field));

  const sub_org_to_disassociate = params.viewType === "super_orgs" ? user?.provider_id! : params.viewType === "sub_orgs" ? subOrgIdToDisassociate : subOrgIdToDisassociate;

  const super_org_to_disassociate = params.viewType === "super_orgs" ? superOrgIdToDisassociate : params.viewType === "sub_orgs" ? user?.provider_id : undefined;

  const disassociateOrganizationDialogProps: DisassociateOrganizationDialogProps = {
    viewType: params.viewType!,
    open: Boolean(subOrgIdToDisassociate) || Boolean(superOrgIdToDisassociate),
    super_org: super_org_to_disassociate,
    onCancel: () => {
      setsuperorgIdToDisassociate("");
      setsubOrgIdToDisassociate("");
    },
    sub_org: sub_org_to_disassociate,
    onDisassocated: () => {
      setsubOrgIdToDisassociate("");
      setsuperorgIdToDisassociate("");
      refreshData();
    },
  };

  const tableProps: CustomProvidersTableProps = {
    onDissociateClick: (fields) => {
      if (params.viewType === "sub_orgs") setsubOrgIdToDisassociate(fields.id);
      if (params.viewType === "super_orgs") setsuperorgIdToDisassociate(fields.id);
      if (params.viewType !== "super_orgs" && params.viewType !== "sub_orgs") setsubOrgIdToDisassociate(fields.id);
    },
    viewType: params.viewType,
    onBillingSwitchClick: (data, status) => {
      setsubOrgToUpdateBilling({ org_id: data.id, status });
    },
    onDeleteButtonClick: handleRemoveProviderClicked,
    onEditButtonClick: handleUpdateProviderClicked,
    onAffiliateClick: (data) => {
      setsubOrgToAffiliate(data.id);
      setsubOrgNameToAffiliate(data.name);
    },
    onSetupeWalletClick: handleAddEWallet,
    isLoading,
    data: preparedData,
    isShowAffiliateAction: false,
    isShowDissociateAction: false,
    isShowEditAction: false,
    isShowsetupewalletAction: false,
    isShowRemoveAction: false,
    countLabelProps: {
      count: totalCount,
    },

    pagnationProps: {
      isNextPageButtonDisabled: (isLastPage || isLoading),
      isPreviousPageButtonDisabled: (isFirstPage || isLoading),
      total: totalCount,
      from: (currentPage - 1) * perPage,
      rowsPerPage: perPage,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
    },
    actionBarProps: {
      affiliateButtonProps: {
        onClick: () => setisOpenAffliateOwnOrgDialog(true),
      },
      addButtonProps: {
        onClick: handleAddProviderClicked,
      },
      searchTextFieldProps: {
        onChange: (e) => handleSearchTextChange(e.currentTarget.value),
      },
      onColumnsStateChange: handleColumnStateChange,
      columnsState: preparedColumnsState,
      isShowColumnButton: true,
      isShowCreateButton: Boolean(params.actionsToInclude?.find((item) => item === "add")),
      isShowSearchTextField: true,
      isShowAffiliateButton: Boolean(params.actionsToInclude?.find((item) => item === "affiliate")),
    },
  };
  return {
    tableProps,
    updateProviderProps,
    addProviderProps,
    removeProviderProps,
    afiliateDialogProps,
    afiliateOwnOrgDialogProps,
    disassociateOrganizationDialogProps,
    isOpenAddPhoneNoteModal,
    handleHidePhoneNote,
    setupAgriWalletProps
  };
};
