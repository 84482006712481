import { Button, ButtonProps } from "@mui/material";
import { IoAddSharp } from "react-icons/io5";

export interface CreateButtonProps extends ButtonProps {}

const CreateButton: React.FunctionComponent<CreateButtonProps> = (props) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<IoAddSharp />}
      {...props}
    />
  );
};

export default CreateButton;

CreateButton.defaultProps = {
  children: "Create",
};
