import { GetServices } from "api/services/get-services";
import { Params, UpdateService } from "api/services/update-service";
import { CustomServicesTableProps } from "components/custom-compnents/custom-services-table/custom-services-table";
import { Fields } from "components/custom-compnents/custom-services-table/custom-services-table.schema";
import { Service } from "models/service";
import React from "react";
import { ConfirmChangeModalProps } from "./confirm-change-modal";

export const useLogic = () => {
  const [dataUpdateIndex, setDataUpdateIndex] = React.useState(0);
  const [data, setData] = React.useState<Service[]>([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmittingChange, setIsSubmittingChange] = React.useState(false);
  const [serviceChangeType, setServiceChangeType] =
    React.useState<string | undefined>();
  const [columnsState, setColumnsState] = React.useState([
    { field: "id", checked: true },
    { field: "name", checked: true },
    // { field: "published", checked: true },
    // { field: "ussd_position", checked: true },
    // { field: "require_location", checked: true },
    // { field: "require_farm", checked: true },
  ]);
  const [isOpenChangeModal, setIsOpenChangeModal] = React.useState(false);
  const [serviceToChange, setServiceToChange] = React.useState<Service | undefined>();

  const handleCancelChange = () => setIsOpenChangeModal(false);

  const handlePerPageChange = (value: number, index: number) => {
    setPerPage(value);
    setCurrentPage(0);
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (perPage * (currentPage + 1) < totalCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  const refreshData = () => {
    setDataUpdateIndex(dataUpdateIndex + 1);
  };

  const handleColumnStateChange = (column: string, checked: boolean) => {
    const fieldIndex = columnsState.findIndex((item) => item.field === column);
    const columnSet = columnsState.map((item) => item);
    columnSet[fieldIndex] = { ...columnSet[fieldIndex], checked };
    setColumnsState(columnSet);
  };

  const HandleConfirmChange = () => {
    if (serviceChangeType && serviceToChange) {
      setIsSubmittingChange(true);
      UpdateService(
        {
          id: serviceToChange.id,
          name: serviceToChange.name,
          order: serviceToChange.order,
          published: serviceToChange.published,
          require_farm: serviceChangeType === 'FarmRequire' ? !serviceToChange.require_farm : undefined,
          require_location: serviceChangeType === 'LocationRequire' ? !serviceToChange.require_location : undefined,
        },
        (error, resp) => {
          setIsSubmittingChange(false);
          if (error) console.log(error);
          else {
            refreshData();
            setIsOpenChangeModal(false);
          }
        }
      );
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    GetServices({ pageSize: perPage, page: currentPage }, (error, resp) => {
      if (error) {
        console.log(error.message);
      } else if (resp) {
        setIsLoading(false);
        setData(resp.items);
        setTotalCount(resp.total);
      }
    });
  }, [currentPage, perPage, dataUpdateIndex]);

  const preparedData = data.map((item) => ({ ...item, selected: false }));

  const confirmChangeDialogProps: ConfirmChangeModalProps = {
    open: isOpenChangeModal,
    onCancel: handleCancelChange,
    ServiceName: serviceToChange?.name,
    DisableConfirm: isSubmittingChange,
    onConfirm: HandleConfirmChange,
  };

  const handleRequireLocationChange = (services: Fields, state: boolean) => {
    setServiceChangeType("LocationRequire");
    setServiceToChange(services);
    setIsOpenChangeModal(true);
  };

  const handleRequireFarmChange = (services: Fields, state: boolean) => {
    setServiceChangeType("FarmRequire");
    setServiceToChange(services);
    setIsOpenChangeModal(true);
  };

  const tableProps: CustomServicesTableProps = {
    onRequireFarmSwitchChange: handleRequireFarmChange,
    onRequireLocationSwitchChange: handleRequireLocationChange,
    isLoading,
    actionBarProps: {
      onColumnsStateChange: handleColumnStateChange,
      columnsState,
      isShowColumnButton: true,
    },
    data: preparedData,
    countLabelProps: {
      count: totalCount,
    },
    pagnationProps: {
      isNextPageButtonDisabled: totalCount <= perPage * (currentPage + 1),
      isPreviousPageButtonDisabled: currentPage === 0,
      total: totalCount,
      from: perPage * currentPage,
      rowsPerPage: perPage,
      onRowPerPageChange: handlePerPageChange,
      onNextPageClick: handleNextPageClick,
      onPreviousPageClick: handlePreviousPageClick,
    },
  };

  return { tableProps, confirmChangeDialogProps };
};
