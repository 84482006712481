import { Grid, useTheme } from "@mui/material";
import React from "react";
import {
  FarmerScreenStateProviderType,
  ProvideFarmerScreenStateTo,
} from "store/reducers/farmer-screen/FarmerScreenReducer";
import FarmerTabBox from "../farmer-overview-sub-screen/farmer-overview-sub-screen";
import { GetFarmerById } from "api/farmer/get-farmer-by-id";
import { useEffect } from "react";
import FarmsTabBox from "./tabs-boxs.tsx/farms/farms-tab-box";
import ProvidersTabBox from "./tabs-boxs.tsx/providers/providers-tab-box";
import MessageHistoryTabBox from "./tabs-boxs.tsx/messages-history/messages-history-tab-box";
import DevicesTabBox from "./tabs-boxs.tsx/devices/devices-tab-box";
import CallbacksTabBox from "./tabs-boxs.tsx/callbacks/callbacks-tab-box";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useParams } from "react-router-dom";
import CustomTabsComponent from "components/custom-compnents/custom-tabs-component/custom-tabs-component";
import {
  IoMail,
  IoPerson
} from "react-icons/io5";
import CertificationsTabBox from "./tabs-boxs.tsx/certification/certifications-tab-box";
import {
  removeLoading,
  setBreadcrumbData,
  setLoading,
} from "store/reducers/breadcrumb/breadcrumb";
import { breadcrumb } from "./farmer-screen.breadcrumb";
import FarmerLabelsSubPage from "../farmer-labels-sub-page/farmer-labels-sub-page";
import TransactionsTabBox from "./tabs-boxs.tsx/transactions/transactions-tab-box";

export interface FarmerScreenProps extends FarmerScreenStateProviderType {}

const FarmerScreen: React.FC<FarmerScreenProps> = ({
  SetFarmerScreenState,
  FarmerScreenState,
}) => {
  const User = useAppSelector((root) => root.appReducer.user);
  const dispatch = useAppDispatch();
  const { farmer_id } = useParams();
  const { Farmer } = FarmerScreenState;
  useEffect(() => {
    dispatch(setLoading());
    GetFarmerById(
      {
          id: farmer_id
      },
      (error, Resp) => {
        console.log(Resp,'Resp')
        console.log(error,'error')
        if (error) {
          console.log(error.message);
        } else {
          dispatch(setBreadcrumbData(breadcrumb({ farmer: Resp?.data })));
          dispatch(removeLoading());
          SetFarmerScreenState({ Farmer: Resp?.data });
        }
      }
    );
  }, []);

  const theme = useTheme();
  const [currentTab, setCurrentTab] = React.useState(0);
  const user = useAppSelector((root) => root.appReducer.user);
  const tabsData = [
    {
      label: "Farmer",
      value: 0,
      icon: <IoPerson size={25} />,
      isVisible: true,
    },
    // {
    //   label: "Farms",
    //   value: 1,
    //   icon: <IoLeaf size={25} />,
    //   isVisible: true,
    // },
    // {
    //   label: "Organizations",
    //   value: 2,
    //   icon: <IoBriefcase size={25} />,
    //   isVisible: user?.menuroles === "admin",
    // },
    // {
    //   label: "Devices",
    //   value: 3,
    //   icon: <IoLocateSharp size={25} />,
    //   isVisible: user?.menuroles !== "user",
    // },
    // {
    //   label: "Callbacks",
    //   value: 4,
    //   icon: <IoCall size={25} />,
    //   isVisible: user?.menuroles !== "user",
    // },
    {
      label: "Message History",
      value: 5,
      icon: <IoMail size={25} />,
      isVisible: true,
    },
    // {
    //   label: "Certification",
    //   value: 6,
    //   icon: <IoRibbon size={25} />,
    //   isVisible: true,
    // },
    // {
    //   label: "Farmer Labels",
    //   value: 7,
    //   icon: <IoMdPricetag size={25} />,
    //   isVisible: true,
    // },
    // {
    //   label: "agriWALLET",
    //   value: 8,
    //   icon: <IoWallet size={25} />,
    //   isVisible: user?.menuroles !== "user",
    //   "data-cy": "farmer-tabs-agriwallet-tab",
    // },
  ];
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTabsComponent
            TabsProps={{
              allowScrollButtonsMobile: true,
              scrollButtons: "auto",
              variant: "scrollable",
              value: currentTab,
              onChange: (e, value) => setCurrentTab(value),
            }}
            TabData={tabsData.filter((item) => item.isVisible)}
          />
        </Grid>
        <Grid item xs={12}>
          {Farmer &&
            (currentTab === 0 ? (
              <FarmerTabBox User={User!} Farmer={Farmer} />
            ) : currentTab === 1 ? (
              <FarmsTabBox farmer_id={Farmer.id} />
            ) : currentTab === 2 && User!.menuroles == "admin" ? (
              <ProvidersTabBox farmer_id={Farmer.id} />
            ) : currentTab === 5 ? (
              <MessageHistoryTabBox farmer_phone={Farmer.phone} />
            ) : currentTab === 3 ? (
              <DevicesTabBox Farmer={Farmer} />
            ) : currentTab === 4 ? (
              <CallbacksTabBox Farmer={Farmer} />
            ) : currentTab === 6 ? (
              <CertificationsTabBox
                user_role={User?.menuroles}
                farmer_id={Farmer.id}
              />
            ) : currentTab === 7 ? (
              <FarmerLabelsSubPage farmer_id={Farmer.id} />
            ) : currentTab === 8 && User!.menuroles !== "user" ? (
              <TransactionsTabBox farmer_id={Farmer.id!} />
            ) : null)}
        </Grid>
      </Grid>
    </>
  );
};

export default ProvideFarmerScreenStateTo(FarmerScreen, true);
