import { Alert, Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import CancelSuccessButtonsGroup from "components/custom-compnents/cancel-success-buttons-group/cancel-success-buttons-group";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import { useStyles } from "./add-farmers.style";
import { IoCloudDownload, IoCloudUpload } from "react-icons/io5";
import CustomMenu from "components/custom-compnents/custom-menu-component/custom-menu-component";
import DownloadFile from "utils/download-file";
import HandleFileUpload from "./handle-file-upload";
import React from "react";
import CustomLabel from "components/custom-compnents/custom-label/custom-label";
import SubmitBulkUpload from "./add-bulk-farmers";
import CustomMenuButton from "components/custom-compnents/custom-menu-button/custom-menu-button";

interface AddFarmersProps {
  open: boolean;
  onAdded?: () => any;
  onCancel?: () => any;
}

const AddFarmers: React.FunctionComponent<AddFarmersProps> = (props) => {
  const { open, onAdded, onCancel } = props;

  const [IsSubmitting, SetIsSubmitting] = React.useState(false);

  const classes = useStyles();

  const HandleClickLocationFileButton = () => {
    DownloadFile(`/files/agriBORA_Kenya_wards.xlsx`);
  };
  const HandleClickTemplateFileButton = () => {
    DownloadFile(`/files/agriBORA_farmer-upload_template.xlsx`);
  };

  const [FarmersListValues, SetFarmersListValues] = React.useState<
    {
      phone: string;
      firstName: string;
      lastName: string;
      ward_id?: string;
      gender?: string;
      birthYear?: string;
    }[]
  >([]);

  const [ErrorArray, SetErrorArray] = React.useState<string[]>([]);

  const handleUplaodButtonClick = () =>
    (document.querySelector('[name="farmers_file"]') as any).click();

  const [IsCheckingFile, SetIsCheckingFile] = React.useState(false);

  const handleUpload = () => {
    if (ErrorArray.length === 0 && FarmersListValues.length > 0) {
      SubmitBulkUpload(
        FarmersListValues,
        SetIsSubmitting,
        SetFarmersListValues,
        onAdded,
        SetErrorArray
      );
    }
  };

  return (
    <CustomDialogV2
      open={open}
      header="Import Farmer Contacts"
      footer={
        <CancelSuccessButtonsGroup
          CancelButtonProps={{ label: "cancel", onClick: onCancel }}
          SuccessButtonProps={{
            label: "add farmer",
            disabled: FarmersListValues.length === 0 || IsSubmitting,
            onClick: handleUpload,
            "data-cy": "import-contacts-submit-button",
          }}
        />
      }
    >
      <input
        accept=".xls, .xlsx"
        onChange={(e) =>
          HandleFileUpload(
            e,
            SetErrorArray,
            SetFarmersListValues,
            SetIsCheckingFile
          )
        }
        type="file"
        name="farmers_file"
        data-cy="contacts-input"
        style={{ display: "none" }}
      />
      <Box width={500} maxWidth="100%" mt={1}>
        <Grid container spacing={2}>
          <Grid item sm={7} xs={12}>
            <Button
              onClick={handleUplaodButtonClick}
              startIcon={
                <>
                  <IoCloudUpload />
                </>
              }
              color="secondary"
              fullWidth
              className={classes.buttonRoot}
            >
              Upload Excel File
            </Button>
          </Grid>
          <Grid item sm={5} xs={12}>
            <CustomMenu
              menuData={[
                {
                  label: "Download farmer contact template",
                  onClick: HandleClickTemplateFileButton,
                },
                // {
                //   label: "Download ward id helper",
                //   onClick: HandleClickLocationFileButton,
                // },
              ]}
            >
              <CustomMenuButton
                startIcon={
                  <>
                    <IoCloudDownload />
                  </>
                }
                fullWidth
              >
                Download Files
              </CustomMenuButton>
            </CustomMenu>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              sx={{ overflow: "auto", maxHeight: 300 }}
            >
              {FarmersListValues.length > 0 && (
                <Grid item xs={12}>
                  <Alert
                    severity="success"
                    data-cy="import-farmers-file-success-alert"
                  >
                    You're about to upload {FarmersListValues.length} farmers.
                  </Alert>
                </Grid>
              )}
              {ErrorArray.map((item) => (
                <Grid item xs={12}>
                  <Alert
                    variant="outlined"
                    key={item}
                    severity="error"
                    data-cy="import-farmers-file-fail-alert"
                  >
                    {item}
                  </Alert>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CustomDialogV2>
  );
};

export default AddFarmers;

AddFarmers.defaultProps = { open: true };
