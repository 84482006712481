import { RouteItem } from "classes/route-item";
import AgentCodesScreen from "components/pages/administration/agent-codes-screen/agent-codes-screen";
import CallbacksScreen from "components/pages/administration/callbacks-screen/callbacks-screen";
import DevicesScreen from "components/pages/administration/devices-screen/devices-screen";
import ProvidersScreen from "components/pages/administration/providers-screen/providers-screen";
import RolesScreen from "components/pages/administration/roles-screen/roles-screen";
import SenderIdsScreen from "components/pages/administration/sender-ids-screen/sender-ids-screen";
import ServicesScreen from "components/pages/administration/services-screen/services-screen";
import UsersScreen from "components/pages/administration/users/users";
import AggregationScreen from "components/pages/aggregation-screen/Aggregation-Screen";
import BroadcastsDetailScreen from "components/pages/broadcasts-details-screen/broadcasts-detail-screen";
import Broadcasts from "components/pages/broadcasts-screen/broadcasts-screen";
import ContactInfoScreen from "components/pages/contact-info-screen/contact-info-screen";
import DirectMessagingScreen from "components/pages/send-message-screen/send-message-screen";
import farmPage from "components/pages/farm-page/farm-page";
import farmerScreen from "components/pages/farmer/farmer-screen";
import FarmersAnalyticsPage from "components/pages/farmers-analytics-screen/farmers-analytics";
import FarmersOverview from "components/pages/farmers-overview/farmers-overview";
import MessageDetailScreen from "components/pages/message-detail-screen/message-detail-screen";
import MessagesOverviewScreen from "components/pages/messages-overview-screen/messages-overview-screen";
import Profile from "components/pages/profile/profile";
import SurveyDetailScreen from "components/pages/survey-detail-screen/survey-detail-screen";
import SurveyScreen from "components/pages/survey-screen/survey-screen";
import { IoChatbubbles, IoChatbubblesOutline, IoHelpCircle, IoHelpCircleOutline, IoPeople, IoPeopleOutline, IoPieChart, IoPieChartOutline, IoPodium, IoPodiumOutline, IoShieldCheckmark, IoShieldCheckmarkOutline, IoStatsChartOutline, IoStatsChartSharp } from "react-icons/io5";
import CertificationScreen from "components/pages/certificate-screen/certificate-screen";
import FarmerLabelKeyPage from "components/pages/farmer-label-key-page/farmer-label-key-page";
import WeatherAnalyticsPage from "components/pages/weather-analytics-page/weather-analytics-page";
import MessageTemplatesPage from "components/pages/message-templates-page/message-templates-page";
import CropMapsPage from "components/pages/crop-maps/crop-maps-page";
import TransactionOverview from "components/pages/transaction-overview/transaction-overview";
import TransferMoneyPage from "components/pages/transfer-money-page/transfer-money-page";

const routes: { Lists: RouteItem[] } = {
  Lists: [
    {
      roles: ["admin", "user", "manager", "org_admin"],
      title: "Farmers",
      path: "farmers",
      name: "Farmers",
      icon: IoPeopleOutline,
      iconActive: IoPeople,
      subList: [
        {
          roles: ["admin", "user", "manager", "org_admin"],
          title: "Farmer Management",
          name: "overview",
          path: "overview",
          isLink: true,
          component: FarmersOverview,
        },
        {
          roles: ["admin", "user", "manager", "org_admin"],
          title: "Farmer",
          name: "farmer",
          path: ":farmer_id",
          component: farmerScreen,
          hideInMenu: true,
        },
      ],
    },
    // {
    //   title: "Analytics",
    //   roles: ["admin", "manager", "user", "org_admin"],
    //   isLink: false,
    //   name: "analytics",
    //   path: "analytics",
    //   subList: [
    //     {
    //       roles: ["admin", "user", "manager", "org_admin"],
    //       title: "Farmer Analytics",
    //       name: "farmers analytics",
    //       path: "analytics",
    //       isLink: true,
    //       component: FarmersAnalyticsPage,
    //     },
    //     {
    //       roles: ["admin", "user", "manager", "org_admin"],
    //       title: "Weather Analytics",
    //       name: "weather analytics",
    //       path: "weather_analytics",
    //       isLink: true,
    //       component: WeatherAnalyticsPage,
    //     },
    //     {
    //       name: "crop maps",
    //       path: "crop_maps",
    //       title: "Crop Maps",
    //       component: CropMapsPage,
    //       isLink: true,
    //       roles: ["admin", "user", "manager"],
    //     },
    //   ],
    //   icon: IoPodiumOutline,
    //   iconActive: IoPodium,
    // },
    {
      roles: ["admin", "user", "manager", "org_admin"],
      title: "Messaging",
      name: "messaging",
      path: "messaging",
      icon: IoChatbubblesOutline,
      iconActive: IoChatbubbles,
      subList: [
        {
          roles: ["admin", "user", "manager", "org_admin"],
          title: "Message Status",
          name: "message status",
          path: "status",
          isLink: true,
          component: MessagesOverviewScreen,
          subList: [
            {
              title: "Details",
              name: "detail",
              roles: ["admin", "manager", "user", "org_admin"],
              component: MessageDetailScreen,
              path: "details",
            },
          ],
        },
        {
          roles: ["admin", "user", "manager", "org_admin"],
          title: "Send Message",
          name: "send message",
          path: "send",
          isLink: true,
          component: DirectMessagingScreen,
        },
        {
          roles: ["admin", "user", "manager", "org_admin"],
          title: "Broadcasts",
          name: "broadcasts",
          path: "broadcasts",
          isLink: true,
          component: Broadcasts,
          subList: [
            {
              roles: ["admin", "manager", "user", "org_admin"],
              name: "broadcast",
              title: "Broadcast",
              path: ":id",
              component: BroadcastsDetailScreen,
              hideInMenu: true,
            },
          ],
        },
        {
          roles: ["admin", "org_admin"],
          title: "Message Templates",
          name: "message_templates",
          path: "templates",
          isLink: true,
          component: MessageTemplatesPage,
        },
      ],
    },
    // {
    //   name: "finances",
    //   title: "Finances",
    //   roles: ["admin", "manager", "org_admin"],
    //   icon: IoStatsChartOutline,
    //   iconActive: IoStatsChartSharp,
    //   path: "finances",
    //   subList: [
    //     {
    //       name: "transaction_overview",
    //       title: "Transaction Overview",
    //       path: "transaction_overview",
    //       roles: ["admin", "manager", "org_admin"],
    //       isLink: true,
    //       component: TransactionOverview,
    //     },
    //     {
    //       name: "transfer",
    //       title: "Transfer",
    //       path: "transfer",
    //       roles: ["admin"],
    //       isLink: true,
    //       component: TransferMoneyPage,
    //     },
    //   ],
    // },
    {
      roles: ["admin", "user", "manager", "org_admin"],
      path: "surveys",
      title: "Surveys",
      name: "surveys",
      icon: IoPieChartOutline,
      iconActive: IoPieChart,
      isLink: true,
      component: SurveyScreen,
      subList: [
        {
          name: "survey",
          path: ":survey_id",
          component: SurveyDetailScreen,
          title: "Surveys",
          roles: ["admin", "manager", "user", "org_admin"],
          hideInMenu: true,
        },
      ],
    },
    {
      roles: ["admin", "manager", "org_admin"],
      title: "Administration",
      path: "administration",
      name: "administration",
      icon: IoShieldCheckmarkOutline,
      iconActive: IoShieldCheckmark,
      subList: [
        {
          roles: ["admin", "org_admin"],
          title: "Users",
          name: "users",
          path: "users",
          isLink: true,
          component: UsersScreen,
        },
        // {
        //   roles: ["admin"],
        //   title: "Roles",
        //   name: "roles",
        //   path: "roles",
        //   isLink: true,
        //   component: RolesScreen,
        // },
        {
          roles: ["admin", "org_admin"],
          title: "Organizations",
          name: "providers",
          path: "providers",
          isLink: true,
          component: ProvidersScreen,
        },
        {
          roles: ["admin"],
          title: "Services",
          name: "services",
          path: "servcies",
          isLink: true,
          component: ServicesScreen,
        },
        // {
        //   roles: ["admin"],
        //   title: "Callbacks",
        //   name: "callbacks",
        //   path: "callbacks",
        //   isLink: true,
        //   component: CallbacksScreen,
        // },
        // {
        //   roles: ["admin"],
        //   title: "Devices",
        //   name: "devices",
        //   path: "devices",
        //   isLink: true,
        //   component: DevicesScreen,
        // },
        {
          roles: ["admin", "manager", "org_admin"],
          title: "Registration Code",
          name: "registration code",
          path: "registration_code",
          isLink: true,
          component: AgentCodesScreen,
        },
        {
          roles: ["admin"],
          title: "Sender IDs",
          name: "sender ids",
          path: "sender_ids",
          isLink: true,
          component: SenderIdsScreen,
        },
        // {
        //   roles: ["admin"],
        //   title: "Certificates",
        //   name: "certificates",
        //   path: "certificates",
        //   isLink: true,
        //   component: CertificationScreen,
        // },
        // {
        //   roles: ["admin", "manager", "org_admin"],
        //   title: "Farmer Label Keys",
        //   name: "farmer_label_keys",
        //   path: "farmer_label_keys",
        //   isLink: true,
        //   component: FarmerLabelKeyPage,
        // },
      ],
    },
    {
      title: "Help",
      path: "help",
      name: "helps",
      icon: IoHelpCircleOutline,
      iconActive: IoHelpCircle,
      roles: ["admin", "manager", "user", "org_admin"],
      subList: [
        {
          roles: ["admin", "manager", "user", "org_admin"],
          title: "Contact Info",
          name: "contact info",
          path: "contact_info",
          isLink: true,
          component: ContactInfoScreen,
        },
      ],
    },
    {
      title: "Profile",
      path: "profile",
      name: "profile",
      isLink: true,
      component: Profile,
      roles: ["admin", "manager", "user", "org_admin"],
      hideInMenu: true,
    },
    // {
    //   title: "Farms",
    //   path: "farms/:farm_id",
    //   name: "farms",
    //   component: farmPage,
    //   roles: ["admin", "manager", "user", "org_admin"],
    //   hideInMenu: true,
    //   subList: [
    //     {
    //       name: "aggregation_activities",
    //       path: "aggregation_activities/:activity_id",
    //       component: AggregationScreen,
    //       roles: ["admin", "manager", "user", "org_admin"],
    //       title: "Aggergation Activity",
    //     },
    //   ],
    // },
  ],
};

export default routes;
