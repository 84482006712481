import { AxiosError } from "axios";
import { AxiosInstance } from "../../configs/axios";

export interface Params {
  id?: string;
  page?: number;
  pageSize?: number,

}

interface Response { data: any }

type callbackType = (error: null | AxiosError, resp: Response | null) => any;
type GetProviderByIdType = (Params: Params, callback: callbackType) => void;
const GetProviderById: GetProviderByIdType = (Params, callback) => {
  AxiosInstance.get("/organizations/" + Params.id)
    .then((resp) =>
      callback(null, {
        data: resp.data.data
      })
    )
    .catch((error) => callback(error, null));
};

export { GetProviderById };
