import { AxiosError } from "axios";
// import cleanDeep from "clean-deep";
import { AxiosInstance } from "configs/axios";
// import { EnumType, jsonToGraphQLQuery } from "json-to-graphql-query";
import humanize from "humanize-graphql-response";
import { Farmer } from "../../models/farmers";
import _ from "lodash";


interface Params {
  id: string | undefined;
}

interface Resp {
  data: Farmer;
}

const GetFarmerById = (Params: Params, callback: (error: null | AxiosError, data: null | Resp) => void) => {
  AxiosInstance.get("/farmers/" + Params.id)
    .then((resp) => {

      try {
        callback(null, {
          data: {
            ...resp.data.data,
            firstName: resp.data.data.first_name,
            lastName: resp.data.data.last_name,
            birthYear: resp.data.data.birth_year,
            phone: resp.data.data.phone,
            source: resp.data.data.source
          }
        })
      } catch (error) {
        console.log(error, 'error')
      }
    })
    .catch((error) => callback(error, null));
};

export { GetFarmerById };
