import { AxiosError } from "axios";
import humanize from "humanize-graphql-response";
import { AxiosInstance } from "configs/axios";

interface Resp {
  data: any
}

interface Params {
  id?: string;
}
type GetProvidersCodeByIdType = (
  Params: Params,
  callback: (error: AxiosError | null, resp: Resp | null) => any
) => any;
export const GetProvidersCodeById: GetProvidersCodeByIdType = (Params, callback) => {
  AxiosInstance.get(`/provider-codes/${Params?.id}`, { params: Params })
    .then((resp) => {
      callback(null, {
        data: {
          ...resp.data.data,
          id: resp.data.data.company_code_id,
          provider_id: resp.data.data.company_registry_id,
          provider: {
            id: resp.data.data.company_registry_id,
            name: resp.data.data.company_id,
          }
        }
      });
    })
    .catch((error) => {
      callback(error, null);
    });
};
