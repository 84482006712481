import MaterialTable from "@material-table/core";
import CustomPagnation from "components/custom-compnents/custom-pagnation/custom-pagnation";
import { Fields, Params, schema } from "./custom-services-table.schema";
import { Box, Grid } from "@mui/material";
import CountLabel, { CountLabelProps } from "../count-label/count-label";
import { CustomPagnationProps } from "components/custom-compnents/custom-pagnation/custom-pagnation";
import CustomFiltersContainer, { CustomFiltersContainerProps } from "../custom-filters-container/custom-filters-container";
import CustomServicesTableActionBar, { CustomServicesTableActionBarProps } from "components/custom-compnents/custom-services-table-action-bar/custom-services-table-action-bar";
import FarmsActivitesTableFilterForm, { FarmsActivitesTableFilterFormProps } from "components/forms/farms-activites-table-filter-form/farms-activites-table-filter-form";

export interface CustomServicesTableProps {
  countLabelProps?: CountLabelProps;
  showFilterArea?: boolean;
  filterContainerProps?: CustomFiltersContainerProps;
  filterFormProps?: FarmsActivitesTableFilterFormProps;
  pagnationProps?: CustomPagnationProps;
  actionBarProps?: CustomServicesTableActionBarProps;
  data: Fields[];
  isLoading?: boolean;
  customSelection?: Params["customSelection"];
  onOrderChange?: (orderBy: number, orderDirection: "desc" | "asc") => void | undefined;
  onRequireFarmSwitchChange?: Params["onRequireFarmSwitchChange"];
  onRequireLocationSwitchChange?: Params["onRequireLocationSwitchChange"];
}

const CustomServicesTable: React.FunctionComponent<CustomServicesTableProps> = (props) => {
  const { countLabelProps, showFilterArea, pagnationProps, filterContainerProps, filterFormProps, actionBarProps, data, isLoading, customSelection, onOrderChange, onRequireFarmSwitchChange, onRequireLocationSwitchChange } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ paddingLeft: 0 }}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ paddingLeft: 0 }}>
          <Grid item md={3} xs={12}>
            <CountLabel {...countLabelProps} label="All Services" />
          </Grid>
          <Grid item md={9} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Box width={840} maxWidth="100%">
                <CustomServicesTableActionBar {...actionBarProps} />
              </Box>
            </Box>
          </Grid>
          {showFilterArea && (
            <Grid item xs={12}>
              <CustomFiltersContainer {...filterContainerProps}>
                <FarmsActivitesTableFilterForm {...filterFormProps} />
              </CustomFiltersContainer>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          {...schema({
            onRequireFarmSwitchChange,
            onRequireLocationSwitchChange,
            customSelection,
            data,
            columnState: actionBarProps?.columnsState,
          })}
          isLoading={isLoading}
          onOrderChange={onOrderChange}
        />
      </Grid>
      {/* <Grid item xs={12}>
        {pagnationProps && <CustomPagnation {...pagnationProps} />}
      </Grid> */}
    </Grid>
  );
};

export default CustomServicesTable;
