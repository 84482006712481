import { AxiosError } from "axios";
import { AxiosInstance } from "../../configs/axios";
import { IProvider } from "../../models/provider";

import { PaginationProps } from '../../interfaces/custom-pagination';
const humanize = require("humanize-graphql-response");

export interface Params extends PaginationProps {
  page?: number;
  pageSize?: number;
  id?: string;
  is_active?: boolean;
  search?: string;
  sub_orgs?: boolean;
  super_orgs?: boolean;
  with_ewallet_user?: boolean;
  farmer_id_list?: string[];
  store_gps_lat?: number;
  store_gps_lon?: number;
}

interface Response {
  totalCount: number;
  providers: IProvider[];
  pageInfo?: {}
}
type callbackType = (error: null | AxiosError, resp: Response | null) => any;
type GetProviderType = (Params: Params, callback: callbackType) => void;
const GetProvider: GetProviderType = (Params, callback) => {
  AxiosInstance.get("/organizations", { params: Params })
    .then((resp) =>{
      callback(null, {
        providers: humanize(resp.data.data.all_providers).nodes.map(
          (item: any) => ({
            ...item,
            smsSigName: item.sms_sig_name,
            isActive: item.is_active,
            isBillingOrganization: item.is_billing_organization,
          })
        ),
        totalCount: resp.data.data.all_providers.total_count,
        pageInfo: resp.data.data.all_providers.page_info
      })
    }

    )
    .catch((error) => callback(error, null));
};

export { GetProvider };
