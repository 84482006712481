import {
  Autocomplete,
  AutocompleteChangeReason,
  Button,
  ButtonProps,
  Chip,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";

export interface Key {
  label: string;
  value: string;
}

export interface Value {
  label: string;
  value: string;
}

export interface KeyValues {
  key: { id: string; label: string };
  values: { id: string; label: string }[];
}
interface FarmerLabelsFilterProps {
  addButtonProps?: ButtonProps & { "data-cy"?: string };
  clearButtonProps?: ButtonProps;
  value?: KeyValues[];
  onItemDeleteClick?: (item: KeyValues, index: number) => any;
  keyOptions: Key[];
  valueOptions: Value[];
  keyValue?: Key | null;
  valueValue: Value[];
  onKeyInputTextChange?: (value: string) => any;
  onValueInputTextChange?: (value: string) => any;
  submitButtonProps?: ButtonProps & { "data-cy"?: string };
  keyInputDisabled?: boolean;
  valueInputDisabled?: boolean;
  keyNoOptionsLabel?: string;
  valueNoOptionsLabel?: string;
  cancelButtonProps?: ButtonProps;
  isEmptyWrapper?: boolean;
  isNoPadding?: boolean;
  onKeyChange?: (value: Key, reason: AutocompleteChangeReason) => any;
  onValueChange?: (value: Value[], reason: AutocompleteChangeReason) => any;
}

const FarmerLabelsFilter: React.FunctionComponent<FarmerLabelsFilterProps> = (
  props
) => {
  const {
    addButtonProps,
    clearButtonProps,
    value,
    onItemDeleteClick,
    keyOptions,
    valueOptions,
    valueValue,
    keyValue,
    onKeyInputTextChange,
    onValueInputTextChange,
    submitButtonProps,
    valueInputDisabled,
    keyInputDisabled,
    keyNoOptionsLabel,
    valueNoOptionsLabel,
    cancelButtonProps,
    isEmptyWrapper,
    isNoPadding,
    onKeyChange,
    onValueChange,
  } = props;
  const InputData = [
    {
      placeholder: "Farmer Key",
      options: keyOptions,
      value: keyValue,
      onInputTextChange: onKeyInputTextChange,
      disabled: keyInputDisabled,
      noOptionsLabel: keyNoOptionsLabel,
      onChange: onKeyChange,
      "data-cy": "farmers-filter-labels-farmer-key-input",
    },
    {
      placeholder: "Farmer Label",
      Multiple: true,
      options: valueOptions,
      value: valueValue,
      onInputTextChange: onValueInputTextChange,
      disabled: valueInputDisabled,
      noOptionsLabel: valueNoOptionsLabel,
      onChange: onValueChange,
      "data-cy": "farmers-filter-labels-farmer-label-input",
    },
  ];
  const ActionComponentsData = [
    { placeholder: "Add Entry", color: "primary", props: addButtonProps },
    { placeholder: "Clear Set", color: "inherit", props: clearButtonProps },
    {
      placeholder: "Submit",
      color: "success",
      props: submitButtonProps,
      gridProps: { xs: 12 },
    },
    {
      placeholder: "Cancel",
      color: "inherit",
      props: cancelButtonProps,
      gridProps: { xs: 12 },
      variant: "text",
    },
  ];

  return (
    <Box
      component={!isEmptyWrapper ? Paper : undefined}
      p={isNoPadding ? 0 : 2}
      display="inline-block"
      width={350}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {value?.map((item, index) => (
            <Chip
              key={item.key.id}
              sx={{ m: 0.5 }}
              variant="outlined"
              label={`${item.key.label} : ${item.values
                .map((item) => item.label)
                .join(", ")}`}
              onDelete={() => onItemDeleteClick?.(item, index)}
            />
          ))}
        </Grid>
        {InputData.map((item) => (
          <Grid item xs={12} key={item.placeholder}>
            <Autocomplete
              filterSelectedOptions
              noOptionsText={item.noOptionsLabel}
              disabled={item.disabled}
              disablePortal
              onChange={(e, value, reason) =>
                item.onChange?.(value as any, reason)
              }
              filterOptions={(options, state) => options}
              options={item.options as any}
              multiple={item.Multiple}
              value={item.value as any}
              renderInput={(params) => (
                <TextField
                  onChange={(e) =>
                    item.onInputTextChange?.(e.currentTarget.value)
                  }
                  {...params}
                  fullWidth
                  placeholder={item.placeholder}
                />
              )}
              data-cy={item?.["data-cy"]}
            />
          </Grid>
        ))}
        {ActionComponentsData.map((item) => (
          <Grid item xs={6} {...item.gridProps} key={item.placeholder}>
            <Button
              variant={(item.variant ?? "contained") as any}
              fullWidth
              color={item.color as any}
              {...item.props}
            >
              {item.placeholder}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FarmerLabelsFilter;
