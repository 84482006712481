import { Box, Grid, Paper } from "@mui/material";
import CancelSuccessButtonsGroup, {
  CancelSuccessButtonsGroupProps,
} from "../cancel-success-buttons-group/cancel-success-buttons-group";

export interface CustomFiltersContainerProps {
  CancelSuccessButtonGroupProps?: CancelSuccessButtonsGroupProps;
}

const CustomFiltersContainer: React.FunctionComponent<
  CustomFiltersContainerProps
> = (props) => {
  const { children, CancelSuccessButtonGroupProps } = props;
  return (
    <Box component={Paper} px="19px" py="18px">
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item lg={9} xs={12}>
          {children}
        </Grid>
        <Grid item lg={3} xs={12}>
          <CancelSuccessButtonsGroup
            CancelButtonProps={{
              fullWidth: true,
              label: "Cancel",
              ...CancelSuccessButtonGroupProps?.CancelButtonProps,
            }}
            SuccessButtonProps={{
              fullWidth: true,
              label: "Apply Filter",
              "data-cy": "farmers-filter-apply-filters-button",
              ...CancelSuccessButtonGroupProps?.SuccessButtonProps,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomFiltersContainer;
