export class authTokenService {
  static SaveTokenLocally(token: string) {
    localStorage.setItem("token", token); 
  }
  static RemoveTokenLocally() {
    localStorage.removeItem("token");
  }
  static GetTokenLocally() {
    var token = localStorage.getItem("token");
    return token
  }
}