import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { IUser } from "models/user";
import CustomFarmerInfoCard from "components/custom-compnents/custom-farmer-info-card/custom-farmer-info-card";
import CustomTitle from "components/custom-compnents/custom-title/custom-title";
import { IParams, useLogic } from "./farmer-overview-sub-screen.logic";
import CustomInfoCardWithIcon from "components/custom-compnents/custom-info-card-with-icon/custom-info-card-with-icon";
import { IoSwapHorizontalSharp, IoWarningSharp } from "react-icons/io5";
import LocationMap from "components/standalone-components/Location-Map/Location-Map";
import CustomDialogV2 from "components/custom-compnents/custom-dialog-v2/cusotm-dialog-v2";
import CancelDeleteButtonsGroup from "components/custom-compnents/cancel-delete-buttons-group/cancel-delete-buttons-group";
import AddUpdateFarmer from "components/standalone-components/Add-Update-Farmer/Add-Update-Farmer";
import EwalletBalance from "components/standalone-components/ewallet-balance/ewallet-balance";

export interface FarmerTabBoxProps extends IParams {
  User: IUser;
}

const FarmerTabBox: React.FC<FarmerTabBoxProps> = ({ Farmer, User }) => {
  const {
    farmerCardProps,
    lastIntercationDate,
    farmerFarmsLocationsArray,
    isOpenDeleteDialog,
    handleCancelDeleteButtonClick,
    handleDelete,
    isDeleting,
    editFarmerComponentProps,
  } = useLogic({ Farmer });

  return (
    <Grid container spacing={2}>
      <AddUpdateFarmer {...editFarmerComponentProps} />
      <CustomDialogV2
        header={
          <Typography variant="h6" display="inline-flex">
            <IoWarningSharp size={22} style={{ marginRight: 5 }} />
            Delete A farmer
          </Typography>
        }
        open={isOpenDeleteDialog}
        footer={
          <Box display="flex" justifyContent="flex-end">
            <CancelDeleteButtonsGroup
              RootGridProps={{ maxWidth: 300 }}
              CancelButtonProps={{
                label: "Cancel",
                onClick: handleCancelDeleteButtonClick,
              }}
              DeleteButtonProps={{
                label: "Delete Farmer",
                onClick: handleDelete,
                disabled: isDeleting,
                "data-cy": "farmer-delete-dialog-delete-button",
              }}
            />
          </Box>
        }
      >
        <Box maxWidth={500} mt={1}>
          <span>
            Are you sure you want to delete{" "}
            <Typography
              variant="subtitle1"
              display="inline-block"
              lineHeight={0}
            >
              {`${Farmer.firstName} ${Farmer.lastName}`}
            </Typography>{" "}
            from the system?
          </span>
          <Typography lineHeight={0} variant="subtitle1" display="inline-block">
            Note
          </Typography>
          : If you deleted this user then all farms and data related to him will
          deleted too, and this step cannot be undone!.
        </Box>
      </CustomDialogV2>

      <Grid item md={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTitle label="Farmer information" />
          </Grid>
          <Grid item xs={12}>
            <CustomFarmerInfoCard {...farmerCardProps} />
          </Grid>

          <Grid item xs={12}>
            {/* <CustomInfoCardWithIcon
              icon={IoSwapHorizontalSharp}
              titleText="Last USSD interaction"
              primaryText={lastIntercationDate ?? "No Information"}
            /> */}
          </Grid>
          {/* {User.menuroles === "admin" && (
            <Grid item xs={12}>
              <EwalletBalance variant="card" id={Farmer.id} />
            </Grid>
          )} */}
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* <CustomTitle label="Farms Map" /> */}
          </Grid>
          {/* <Grid item xs={12}>
            <LocationMap Solid location={farmerFarmsLocationsArray} />
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FarmerTabBox;
