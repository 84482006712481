import { GetOptins } from "api/optins/get-optins";
import { GetProvidersCode } from "api/providers-code/get-providers-code";
import { GetUssdLog } from "api/ussd-log/get-ussd-log";
import { Farmer } from "models/farmers";
import { IProviderCode } from "models/provider-code";
import { IProviderOptins } from "models/providers-optins";
import React from "react";
import { useAppSelector } from "store/hooks";
import { CustomFarmerInfoCardProps } from "components/custom-compnents/custom-farmer-info-card/custom-farmer-info-card";
import { DeleteFarmersApi } from "api/farmer/delete-farmer";
import { DeleteOptins } from "api/optins/delete-optins";
import { Props } from "components/standalone-components/Add-Update-Farmer/Add-Update-Farmer";

export interface IParams {
  Farmer: Farmer;
}

export const useLogic = (params: IParams) => {
  const { Farmer } = params;

  const user = useAppSelector((root) => root.appReducer.user);

  const [lastIntercationDate, setLastInteractionDate] =
    React.useState<string | undefined>(undefined);

  const [CurrentUserProviderOptin, SetCurrentUserProviderOptin] =
    React.useState<IProviderOptins | undefined>(undefined);

  const [CurrentFarmerSourceAgentCode, SetCurrentFarmerSourceAgentCode] =
    React.useState<IProviderCode[]>([]);

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = React.useState(false);

  const [isOpenEditFarmer, setIsOpenEditFarmer] = React.useState(false);

  const [isDeleting, setIsDeleting] = React.useState(false);

  const handleDelete = () => {
    if (user) {
      setIsDeleting(true);
      DeleteFarmersApi(Farmer.id, () => {
        window.location.href = "/farmers/overview";
        setIsDeleting(false);
      });
    }
  };

  React.useEffect(() => {
    GetUssdLog(
      {
        page: 1,
        pageSize: 10,
        sortInteraction: "descending",
        farmer_id: Farmer.id,
      },
      (error, resp) => {
        if (error) console.log(error.message);
        else if (resp && resp.items.length > 0) {
          setLastInteractionDate(resp.items[0].interaction);
        }
      }
    );

    user &&
      GetOptins(
        { ProvidersIds: [user.provider_id], FarmersIds: [Farmer.id] },
        (error, resp) => {
          if (error) {
            console.log(error.message);
          } else if (resp && resp.totalCount > 0) {
            SetCurrentUserProviderOptin(resp.allProviderOptins[0]);
          }
        }
      );

    Farmer.source &&
      user &&
      GetProvidersCode(
        {
          code_include: Farmer.source,
          provider_id: user.provider_id,
          page: 0,
          pageSize: 10,
        },
        (error, resp) => {
          if (error) {
            console.log(error);
          } else if (resp) {
            SetCurrentFarmerSourceAgentCode(resp.provider_codes);
          }
        }
      );
  }, []);

  const handleDeleteButtonClick = () => setIsOpenDeleteDialog(true);

  const handleCancelDeleteButtonClick = () => setIsOpenDeleteDialog(false);

  const handleEditFarmerButtonClick = () => setIsOpenEditFarmer(true);

  const farmerCardProps: CustomFarmerInfoCardProps = {
    birthYear: Farmer.birthYear,
    gender: Farmer.gender as any,
    name:
      Farmer.firstName || Farmer.lastName
        ? `${Farmer.firstName ?? ""} ${Farmer.lastName ?? ""}`
        : "UNKNOWN",
    phone: Farmer.phone,
    location: Farmer.location,
    showActionArea:
      user?.menuroles === "admin" ||
      user?.menuroles === "manager" ||
      user?.menuroles === "org_admin",
    deleteButtonProps: {
      onClick: handleDeleteButtonClick,
    },
    editButtonProps: {
      onClick: handleEditFarmerButtonClick,
    },
  };

  const farmerFarmsLocationsArray = Farmer?.farms?.map((item) => ({
    lat: item.lat,
    lng: item.lng,
  }));

  const handleCancelEditFarmer = () => setIsOpenEditFarmer(false);

  const handleEditedFarmer = () => {
    window.location.reload();
  };

  const editFarmerComponentProps: Props = {
    variant: "update",
    id: Farmer.id,
    open: isOpenEditFarmer,
    OnCanceleClicked: handleCancelEditFarmer,
    OnFarmerUpdated: handleEditedFarmer,
  };

  return {
    farmerCardProps,
    lastIntercationDate,
    farmerFarmsLocationsArray,
    isOpenDeleteDialog,
    handleCancelDeleteButtonClick,
    handleDelete,
    isDeleting,
    editFarmerComponentProps,
  };
};
